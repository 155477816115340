@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100..900;1,100..900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  scroll-padding: 30px;
}

body {
  background: #121212;
  color: #f1f1f1;
  font-family: "Poppins", sans-serif;
}

header {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f1f1f1;
  height: 100px;
  position: absolute;
  z-index: 999;
  width: 100%;

  a {
    color: inherit;
    text-decoration: none;
  }

  .primaryNavigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    width: 100%;
    padding: 20px;
    max-width: 1200px;

    .menuToggle {
      display: none;
      font-size: 1.5rem;
      cursor: pointer;
    }

    .left {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 30px;

      .logoContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70px;
        aspect-ratio: 1;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .menu {
        display: flex;
        align-items: center;
        justify-content: center;
        list-style: none;
        gap: 15px;
        font-size: 1.1rem;
      }
    }

    .right {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;

      .searchBar {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        height: 45px;
        background: #f1f1f130;
        backdrop-filter: blur(10px);
        padding-inline: 15px;
        border-radius: 70px;
        transition: all 0.3s;

        label {
          width: 20px;
          aspect-ratio: 1;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        input {
          background: none;
          border: none;
          outline: none;
          color: #f1f1f1;
          width: 100%;
          height: 100%;
          font-family: inherit;
        }

        ::placeholder {
          color: #f1f1f1;
        }
      }

      .searchBar:has(input:focus) {
        background: #121212;

        input {
          background: transparent;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  header {
    .primaryNavigation {
      position: relative;

      .menuToggle {
        display: block;
      }

      .left {
        position: absolute;
        display: grid !important;
        place-content: center;
        top: 100%;
        left: -1000px;
        height: calc(100vh - 100px);
        width: 100%;
        background: #121212;
        opacity: 0;
        transition: all 0.3s;

        .menu {
          flex-direction: column;
        }
      }

      .left.active {
        left: 0 !important;
        opacity: 1;
      }

      .searchBar {
        input {
          display: none;
        }
      }
    }
  }
}

footer {
  .imageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    width: min(80%, 400px);
    margin-inline: auto;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .socialMediaLogos {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    gap: 20px;
    margin-block-start: 20px;
    margin-block-end: 30px;

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;
      font-size: 2rem;

      a {
        color: inherit;
        text-decoration: none;
        width: 60px;
        aspect-ratio: 1;
        background: #f1f1f130;
        backdrop-filter: blur(10px);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        transition: all 0.3s;
      }

      a:hover {
        background: #f1f1f150;
        color: #121212;
      }
    }
  }
}

a.trailerButton {
  width: 220px;
  height: 40px;
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: white;
  text-decoration: none;
  border-radius: 50px;
  margin-block: 20px;
  transition: all 0.3s;

  &:hover {
    background: #f1f1f1;
    color: #121212;
  }
}
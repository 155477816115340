#HeroSection {
  display: grid;
  place-content: center;
  height: 100dvh;
  width: 100%;
  overflow: hidden;

  > * {
    grid-area: 1/1;
  }

  .background {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;

    z-index: 0;

    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .background::after {
    content: "";
    background: linear-gradient(
      180deg,
      rgba(18, 18, 18, 0.25) 31.5%,
      #121212 100%
    );

    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .container {
    width: min(1100px, 100%);
    margin-inline: auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    gap: 30px;
    z-index: 2;
    padding: 20px;

    .imageContainer {
      width: 60%;

      img {
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .bouncingButton {
      width: 50px;
      display: flex;
      align-items: center;
      animation: infinite 2s forwards bounce;

      img {
        width: 100%;
      }
    }

    h3 {
      width: min(100%, 600px);
      color: #f1f1f1;
      font-size: 20px;
      font-weight: 400;
      text-align: center;
    }
  }
}

#MovieList {
  width: min(1100px, 100%);
  margin-inline: auto;
  padding-inline: 15px;

  .posterGallery {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 900px;
    margin-inline: auto;
    gap: 20px;

    .poster {
      width: min(300px, 90%);
      text-align: center;
      text-decoration: none;
      color: inherit;

      h3 {
        color: #f1f1f1;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-block: 10px;
        font-weight: 400;
      }

      .imageContainer {
        width: min(400px, 100%);
        aspect-ratio: 11/17;
        background: #f1f1f1;
        border-radius: 10px;
        overflow: hidden;
        
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }
}

section:has(#MovieList) {
  /* padding-block: 100px; */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100dvh;
  /* min-height: 600px; */

  .posterGallery {
    display: grid !important;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    width: 100% !important;

    .poster {
      margin-inline: auto;
    }
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(5px);
  }

  50% {
    transform: translateY(-10px);
  }
}

/* EMBLA CAROUSEL STYLES */

.embla__container {
  display: flex;
  gap: 10px;
  margin-left: 0 !important;

  .embla__slide {
    background: white;
    color: black;
    aspect-ratio: 16/9 !important;
    flex: 1 0 min(500px, 100%);

    display: flex;
    align-items: center;
    justify-content: center;

    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}

.embla {
  max-width: 70rem;
  margin: auto;
  margin-block: 100px;
  padding-inline: 20px;
  --slide-height: 19rem;
  --slide-spacing: 1rem;
  --slide-size: 100%;
  --slide-spacing-sm: 1.6rem;
  --slide-spacing-lg: 2rem;
}

.embla__viewport {
  overflow: hidden;
  /* padding-inline-start: 31px; */
}

.embla__container {
  backface-visibility: hidden;
  width: 100%;
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
  margin-left: 0 !important;
}

@media (min-width: 750px) {
  .embla__container {
    margin-left: calc(var(--slide-spacing-sm) * -1);
  }
}

@media (min-width: 1200px) {
  .embla__container {
    margin-left: calc(var(--slide-spacing-lg) * -1);
  }
}
.embla__controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.2rem;
  margin-top: 1.8rem;

  h3 {
    font-weight: 400;
    font-size: 1.4rem;
  }
}
.embla__buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.6rem;
  align-items: center;
  place-content: right;
  margin-block-end: 20px;
}
.embla__button {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 35px;
  height: 35px;
  z-index: 1;
  border-radius: 50%;
  color: var(--text-body);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
}
.embla__button:disabled {
  color: var(--detail-high-contrast);
}
.embla__button__svg {
  width: 35%;
  height: 35%;
}
.embla__dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
}
.embla__dot {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  width: 2.6rem;
  height: 2.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.embla__dot:after {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  content: "";
}
.embla__dot--selected:after {
  box-shadow: inset 0 0 0 0.2rem var(--text-body);
}

/* #MovieTrailers {
  max-width: 1100px;
  border-radius: 30px;
  margin: auto;
  --slide-height: 19rem;
  --slide-spacing: 1rem;
  --slide-size: 100%;

  .embla__viewport {
    overflow: hidden;
  }

  .embla__container {
    display: flex;
    touch-action: pan-y pinch-zoom;
    margin-left: calc(var(--slide-spacing) * -1);
    border-radius: 10px;
    overflow: hidden;
  }
  .embla__slide {
    transform: translate3d(0, 0, 0);
    flex: 0 0 var(--slide-size);
    min-width: 0;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  .embla__slide__number {
    box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
    border-radius: 1.8rem;
    font-size: 4rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--slide-height);
    user-select: none;
  }
  .embla__controls {
    display: grid;
    grid-template-columns: auto 1fr;
    justify-content: space-between;
    gap: 1.2rem;
    margin-top: 1.8rem;
  }
  .embla__buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.6rem;
    align-items: center;
  }
  .embla__button {
    -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    touch-action: manipulation;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
    box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
    width: 3.6rem;
    height: 3.6rem;
    z-index: 1;
    border-radius: 50%;
    color: var(--text-body);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .embla__button:disabled {
    color: var(--detail-high-contrast);
  }
  .embla__button__svg {
    width: 35%;
    height: 35%;
  }
  .embla__dots {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
  }
  .embla__dot {
    -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    touch-action: manipulation;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
    width: 2.6rem;
    height: 2.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  .embla__dot:after {
    box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    content: "";
  }
  .embla__dot--selected:after {
    box-shadow: inset 0 0 0 0.2rem var(--text-body);
  }
} */

#MovieTrailer {
  width: min(1100px, 100%);
  overflow: hidden;
  background: white;
  margin-inline: auto;
  border-radius: 10px;
  margin-block-start: 150px;
  margin-block-end: 100px;

  .container {
    width: 100%;
    height: 100%;
    aspect-ratio: 16/9;
    display: flex;
    align-items: center;
    justify-content: center;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

#News {
  margin-block: 150px;
  max-width: 1100px;
  margin-inline: auto;
  padding-inline: 15px;

  .container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;

    .newsItem {
      display: grid;
      gap: 10px;
      text-decoration: none;
      color: inherit;
      max-width: 350px;
      margin-inline: auto;

      .content {
        display: grid;
        gap: 5px;

        h5 {
          font-size: 22px;
          font-weight: 600;
        }

        p {
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          opacity: 0.8;
        }
      }

      .imageContainer {
        width: 100%;
        aspect-ratio: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }
}

#Instagram {
  display: grid;
  place-content: center;
  width: 80%;
  margin-inline: auto;

  .imageContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      object-fit: cover;
      object-position: center;
    }
  }
}

#MovieHero {
  height: min(700px, 100dvh);
  display: flex;
  align-items: center;
  position: relative;

  .background {
    position: absolute;
    z-index: -1;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    &::after {
      width: 100%;
      height: 100%;
      content: "";
      position: absolute;
      background: linear-gradient(
        180deg,
        rgba(18, 18, 18, 0.45) 31.5%,
        #121212 100%
      );
    }
  }

  .container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: min(1100px, 100%);
    margin-inline: auto;

    h1 {
      font-size: 3.5rem;
      line-height: 4.2rem;
      width: min(650px, 100%);
      text-align: center;
    }

    .details {
      display: flex;
      align-items: center;
      justify-content: center;
      list-style: none;
      gap: 20px;
      margin-block: 20px;

      li {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        i {
          font-size: 2rem;
        }
      }
    }
  }
}

#Synopsis {
  width: min(1100px, 100%);
  padding-inline: 15px;
  margin-inline: auto;
  text-align: center;
  margin-block-start: -40px;
  padding-block-end: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h2 {
    font-size: 3rem;
    padding-block: 20px;
  }

  p {
    font-size: 1.5rem;
    line-height: 2.5rem;
    width: min(750px, 100%);
  }
}

#MovieCast {
  padding-inline: 20px;
  padding-block-end: 100px;

  .container {
    width: min(1100px, 100%);
    margin-inline: auto;

    h2 {
      text-align: center;
      font-size: 3rem;
      margin-block: 20px;
    }

    .castGallery {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      width: 100%;
      gap: 25px;

      .actor {
        text-align: center;

        h3 {
          font-size: 1.3rem;
        }
      }
    }
  }
}